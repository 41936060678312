const DonateFilterBtn = ({
  handleShowFilterOffCanvas,
}: {
  handleShowFilterOffCanvas: () => void;
}) => {
  return (
    <button
      className="btn  dark-border-start rounded-0 px-3"
      onClick={handleShowFilterOffCanvas}
    >
      <i className="fe-sliders fs-xxl"></i>{" "}
    </button>
  );
};

export default DonateFilterBtn;
